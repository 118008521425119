<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="1.6 Article Stack Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="1.6 Article Stack Test Description"
              size="medium"
            />
            <p class="p-light">
              The 1.6 Article Stack Tests is very similar to the UN Series 6 (b)
              tests with the exception that one trial is completed confined and
              one unconfined. This test is waived for non-detonable candidates.
            </p>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test measures the susceptibility of the article to explosion
              or detonation when impacted with an armor piecing bullet of a
              given energy. Detonation, explosion, or fragments thrown more than
              15 m or other reaction more severe than burning (as described in
              Appendix 8 of the UN MTC) indicate the article is not a candidate
              as a 1.6 Article.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Number of articles</td>
                    <td>
                      A donor and acceptor article are tested to evaluate
                      propagation for both confined and unconfined conditions
                    </td>
                    <td></td>
                    <td>
                      One donor and one acceptor article are tested confined and
                      unconfined.
                    </td>
                  </tr>
                  <tr>
                    <td>Confining medium and quantity</td>
                    <td>
                      Provide a displaceable confining medium that resists the
                      venting of hot combustion gases
                    </td>
                    <td>
                      <small
                        >Simulate typical transport conditions (package
                        stacking)</small
                      >
                    </td>
                    <td>
                      The preferred method of confinement consists of
                      containers, similar in shape and size to the test package,
                      completely filled with earth or sand and placed as closely
                      as possible around the test package to a minimum thickness
                      of confinement, in every direction of 1 m. Alternative
                      methods of confinement are to use boxes or bags filled
                      with earth or sand placed around and on top of the package
                      or to use loose sand. If loose sand is used for
                      confinement, the stack should be covered or protected to
                      ensure that no sand falls into the interstices between
                      adjacent packages or non-packaged articles.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Ignition stimulus (detonator, igniter, or device function)
                    </td>
                    <td>
                      Ensure ignition/ initiation of the substance or article as
                      intended or designed (or equivalent)
                    </td>
                    <td>
                      <small
                        >Most likely incident to be encountered during
                        transport</small
                      >
                    </td>
                    <td>
                      The method of ignition depends on the how the device/
                      substance is intended to function as per guidelines in the
                      UN Manual of Tests and Criteria, Test 7(k).
                    </td>
                  </tr>
                  <tr>
                    <td>Witness plate</td>
                    <td>Evidence of reaction type</td>
                    <td></td>
                    <td>3.0 mm thick mild steel</td>
                  </tr>
                  <tr>
                    <td>Blast measurement equipment</td>
                    <td>Evidence of reaction type</td>
                    <td></td>
                    <td>Optional</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Detonation</td>
                    <td>
                      Immediate consumption of the entire substance in the
                      article
                    </td>
                    <td>
                      Primary assessment is rapid plastic deformation of the
                      metal casing in contact with the explosive substance with
                      accompanied shock wave corresponding to mass and type of
                      the substance. Secondary assessment includes damage to
                      witness plate(s) and corresponding blast effects such as
                      craters or other damage to surroundings. NOT A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>Partial detonation</td>
                    <td>
                      Immediate consumption of a portion of the entire substance
                      in the article
                    </td>
                    <td>
                      Primary assessment is rapid plastic deformation of a
                      portion of the metal casing in contact with the explosive
                      substance with accompanied shock wave corresponding to
                      less than that expected for the mass and type of the
                      substance. Secondary assessment includes damage to witness
                      plate(s) and corresponding blast effects such as craters
                      or other damage to surroundings. NOT A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>Explosion</td>
                    <td>Rapid consumption of the substance in the article</td>
                    <td>
                      Primary assessment is fracturing of the metal casing
                      (larger pieces than seen in a detonation) in contact with
                      the explosive substance. Secondary assessment includes a
                      pressure wave of a smaller magnitude and longer duration
                      than seen in a detonation event with damage to witness
                      plate(s) and long-distance scattering of burning or
                      unburned pieces of the substance or article. CANDIDATE AS
                      A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>Deflagration</td>
                    <td>Combustion of the substance in the article</td>
                    <td>
                      Primary assessment is combustion (longer reaction time
                      than an explosion) of some or all of the substance with
                      rupture of the article into a few large pieces with at
                      least one piece traveling beyond 15m with energy &gt;20J.
                      Secondary assessment may include a pressure wave of a
                      small magnitude and long duration (less than seen in an
                      explosion event). CANDIDATE AS A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>Burn</td>
                    <td>Burning of the substance in the article</td>
                    <td>
                      Primary assessment is a low pressure burn of some or all
                      of the substance in the article with likely associated
                      rupture of the article however no item travels beyond 15m
                      and does NOT have an associated energy &gt;20J. CANDIDATE
                      AS A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>No Reaction</td>
                    <td>Substance in the article does not sustain burning</td>
                    <td>
                      Primary assessment is no sustained burning of the article
                      and no fragmentation of the article. CANDIDATE AS A 1.6
                      ARTICLE
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">
                *Assessments are taken together to determine if a detonation
                event occurred.
              </p>
            </div>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
